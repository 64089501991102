 <template>
        <div class="banner-card" :style="`background-image: url(${bgImg});`">
            <div class="banner-card-container" :style="bodyStyle">
                <div class="left">
                    <slot name="left">
                        <div class="left-title" :style="titleStyle">{{ title }}</div>
                        <div class="left-content" :style="contentStyle">{{ content }}</div>
                        <div class="left-btn" @click="$emit('btnClick')" v-if="showBtn">{{ btnTxt || '申请试用' }}</div>
                         <div class="left-call" v-if="showBtn">或致电咨询: 023-966500</div>
                    </slot>
                </div>
                <div class="right">
                    <img class="right-imgBox" :style="imgStyle" :src="imgSrc">
                </div>
            </div>
        </div>
    </template>
    <script>
    const bgImg = require('@/assets/img/home/beijjing.png')
    export default {
        data() {
            return {
                bgImg:this.fbgImg,
            }
        },
        props: {
            imgSrc: String,
            bodyStyle: {
                type: String,
                default: "",
            },
            btnTxt: {
                type: String,
                default: "",
            },
            image: {
                type: String,
                default: "",
            },
            title: String,
            content: {
                type: String,
                default: "",
            },
            showBtn: {
                type: Boolean,
                default: true
            },
            imgStyle: {
                type: String,
                default: "",
            },
            titleStyle: {
                type: String,
                default: "",
            },
            contentStyle: {
                type: String,
                default: "",
            },
            fbgImg:{
                type: String,
                default: require('@/assets/img/home/beijjing.png'),
            }
        }
    }
    </script>

    <style scoped lang="scss">


    .banner-card {
      background-color: #0045B7;
      box-sizing: border-box;
      height: 400px;
      width: 100%;
      background-size: 100% 100%;
      &-container {
        width: 1200px;
        display: flex;
        align-items: center;
        margin: auto;
        justify-content: space-around;
        height: 100%;

        .left {
          width: 720px;

          &-title {
            font-size: 48px;
            font-weight: bold;
            color: #fff;
            margin-bottom: 20px;
            line-height: normal;
          }

          &-content {
            color: #fff;
            text-align: justify;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.8;
          }

          &-btn {
            width: 140px;
            height: 50px;
            background-color: #fff;
            border-radius: 6px;
            font-size: 18px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #0B62B5;
            cursor: pointer;
            margin-top: 20px;
          }

          &-call {
            margin-top: 18px;
            font-size: 16px;
            color: #fff;
          }
        }

        .right {
          width: 530px;

          &-imgBox {
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
    </style>
