<template>
    <div style="min-width: 1360px;">
        <new-cloude-header></new-cloude-header>
        <new-home-header-banner :show-btn="false" title="知识产权大数据智能监测平台解决方案" titleStyle="font-size:30px" btnTxt="立即咨询" @btnClick="btnClick" :imgSrc="`${require('@/assets/img/home/intellectualProperty_home.png')}`" :content="content"/>
        <item :imageSrc="`${require('@/assets/img/home/intellectualProperty_1.png')}`" title="企业价值大数据智能分析平台解决方案" :content="content1" :imgStyle="{width:'470px',height:'265px'}">
            <div class="btns2" slot="btns">
<!--                <div class="btn info">立即咨询</div>-->
<!--                <div style="margin-top: 18px;font-size: 14px;">致电咨询: 023-966500</div>-->
            </div>
        </item>
        <item :imageSrc="`${require('@/assets/img/home/intellectualProperty_2.png')}`" title="产业大数据智能监测平台解决方案" :content="content2" :imgStyle="{width:'470px',height:'265px'}" :bodyStyle="{backgroundColor:'#F0F2FF'}" imgRight>
            <div class="btns2" slot="btns">
<!--                <div class="btn info">立即咨询</div>-->
<!--                <div style="margin-top: 18px;font-size: 14px;">致电咨询: 023-966500</div>-->
            </div>
        </item>
        <item :imageSrc="`${require('@/assets/img/home/intellectualProperty_3.png')}`" title="政策大数据平台建设解决方案" :content="content3" :imgStyle="{width:'470px',height:'265px'}">
            <div class="btns2" slot="btns">
<!--                <div class="btn info">立即咨询</div>-->
<!--                <div style="margin-top: 18px;font-size: 14px;">致电咨询: 023-966500</div>-->
            </div>
        </item>
        <item :imageSrc="`${require('@/assets/img/home/intellectualProperty_4.png')}`" title="企业成长培育服务平台建设解决方案" :content="content4" :imgStyle="{width:'470px',height:'265px'}" :bodyStyle="{backgroundColor:'#F0F2FF'}" imgRight>
            <div class="btns2" slot="btns">
<!--                <div class="btn info">立即咨询</div>-->
<!--                <div style="margin-top: 18px;font-size: 14px;">致电咨询: 023-966500</div>-->
            </div>
        </item>
        <item :imageSrc="`${require('@/assets/img/home/intellectualProperty_5.png')}`" title="智慧政务服务平台建设解决方案" :content="content5" :imgStyle="{width:'470px',height:'265px'}">
            <div class="btns2" slot="btns">
<!--                <div class="btn info">立即咨询</div>-->
<!--                <div style="margin-top: 18px;font-size: 14px;">致电咨询: 023-966500</div>-->
            </div>
        </item>
        <item :imageSrc="`${require('@/assets/img/home/intellectualProperty_6.png')}`" title="企业融资大数据平台解决方案 " :content="content6" :imgStyle="{width:'470px',height:'265px'}" :bodyStyle="{backgroundColor:'#F0F2FF'}" imgRight>
            <div class="btns2" slot="btns">
<!--                <div class="btn info">立即咨询</div>-->
<!--                <div style="margin-top: 18px;font-size: 14px;">致电咨询: 023-966500</div>-->
            </div>
        </item>
        <item :imageSrc="`${require('@/assets/img/home/intellectualProperty_7.png')}`" title="知识产权公共服务平台建设解决方案" :content="content7" :imgStyle="{width:'470px',height:'265px'}">
            <div class="btns2" slot="btns">
<!--                <div class="btn info">立即咨询</div>-->
<!--                <div style="margin-top: 18px;font-size: 14px;">致电咨询: 023-966500</div>-->
            </div>
        </item>
        <item :imageSrc="`${require('@/assets/img/home/intellectualProperty_8.png')}`" title="中小企业公共服务平台建设解决方案" :content="content10" :imgStyle="{width:'470px',height:'265px'}" :bodyStyle="{backgroundColor:'#F0F2FF'}" imgRight>
            <div class="btns2" slot="btns">
<!--                <div class="btn info">立即咨询</div>-->
<!--                <div style="margin-top: 18px;font-size: 14px;">致电咨询: 023-966500</div>-->
            </div>
        </item>
        <item :imageSrc="`${require('@/assets/img/home/intellectualProperty_9.png')}`" title="智慧园区建设解决方案" :content="content8" :imgStyle="{width:'470px',height:'265px'}">
            <div class="btns2" slot="btns">
<!--                <div class="btn info">立即咨询</div>-->
<!--                <div style="margin-top: 18px;font-size: 14px;">致电咨询: 023-966500</div>-->
            </div>
        </item>
        <item :imageSrc="`${require('@/assets/img/home/intellectualProperty_10.png')}`" title="智慧工厂建设解决方案" :content="content9" :imgStyle="{width:'470px',height:'265px'}" :bodyStyle="{backgroundColor:'#F0F2FF'}" imgRight>
            <div class="btns2" slot="btns">
<!--                <div class="btn info">立即咨询</div>-->
<!--                <div style="margin-top: 18px;font-size: 14px;">致电咨询: 023-966500</div>-->
            </div>
        </item>
        <item :imageSrc="`${require('@/assets/img/home/intellectualProperty_11.png')}`" title="中小企业数字化转型解决方案" :content="content11" :imgStyle="{width:'470px',height:'265px'}">
            <div class="btns2" slot="btns">
<!--                <div class="btn info">立即咨询</div>-->
<!--                <div style="margin-top: 18px;font-size: 14px;">致电咨询: 023-966500</div>-->
            </div>
        </item>
<!--        <applyTrial ref="dialog" :servicePlatform="11" dialogTitle="立即咨询"/>-->

        <new-cloude-footer></new-cloude-footer>
    </div>
</template>
<script>
import item from '@/components/assembly/item'
import applyTrial from '@/components/assembly/applyTrial'
import newHomeHeaderBanner from "@/views/home/newHomeHeaderBanner.vue";
import newCloudeHeader from "@/views/home/newCloudeHeader.vue";
import newCloudeFooter from "@/views/home/newCloudeFooter.vue";

export default {
    components: { item,applyTrial,newHomeHeaderBanner,newCloudeHeader,newCloudeFooter},
    data(){
        return{
            windowWidth:document.body.offsetWidth,
            windowHeight:window.innerHeight,
            content11:'重点开展“两化、三端、四融合”中小企业数字化转型服务工作，“两化”即数字化转型和智能化提升，“三端”即推进以企业用户端、节点端和平台服务端三级的智能应用平台体系建设，“四融”即推动中小企业“事务、服务、财务、业务”到“智企、智服、智管”的一体化深度融合。',
            content10:'深度融合政企交互场景，实现惠企政策主动推送、精准直达，打造服务企业的数字底座、监测管理底座。汇聚优质企服机构，实现统筹运营管理，满足不同发展阶段的中小企业对创新发展要素的相关需求，助力企业高质量发展。',
            content9:'数字化工厂通过数字化技术的应用，实现生产过程的可控性、可视化、可量化和可优化，从而提高生产效率和质量，降低成本和风险，提升企业的竞争力和创新能力。MRO、ERP、MES、WMS、CRM、WMS、SRM等产品为基础，为客户提供数智化工厂整体解决方案。',
            content8:'智慧园区是借助5G、物联网、大数据、BIM、GIS等新一代信息技术，对园区内人流、物流、信息流等数据进行实时收集、存储与分析，实现园区规划设计、实施建设、运营管理等，包括智慧交通系统、智慧能源系统、智慧园区安防系统、智慧环保系统、智慧楼宇系统等多个方面，构建“空间数字化”和“产业数字化”的数字孪生平台，打造兼容多场景多类型园区的统一平台；通过基座+集成+平台+运营的支撑服务体系，提供智慧园区行业级解决方案。',
            content7:'以全球专利、商标、版权、地理标志、集成电路布图设计等知识产权数据为基础，划分知识产权创造、运用、保护、管理、服务五大主题，结合区域产业与技术特色，提供知识产权信息智能检索、统计分析、监控预警、导航等应用，为产业、企业、公众提供全方位的知识产权赋能。',
            content6:'为帮助中小企业高效智能匹配融资产品，也为金融机构精准获客提供引流服务，搭建“银企直贷”中小企业融资智能服务平台，邀请全市金融机构“开店摆摊”，打造“金融淘宝”，平台建设融资申请、信息查询、信用评价、贷后管理、风险监测等功能，通过接入水电、纳税、不动产交易、公积金、司法等20大类、100余项政府部门涉企数据及其他企业数据为基础，逐步完善涉及信用信息数据，推动企业和银行机构双向信息透明，促进银企融资对接高效开展。',
            content5:'在建设智慧城市的大背景下，发展信息经济和智慧经济，实现经济和社会转型升级的必由之路。智慧政务是广泛运用物联网、云计算、移动互联网、人工智能、数据挖掘等现代信息技术，通过资源整合、流程优化、业务协同，提高政府办公、服务、监管、决策的智能化水平，从而形成高效、集约、便民的服务型政府运营模式。',
            content4:'在国家大力鼓励和支持中小企业背后，“专精特新”成为各个中小企业破局发展的必由之路。然而企业的成长是一项系统工程，由于中小企业在资金、资源、人才、技术等发展要素方面相对薄弱，为了帮助企业发展核心优势，推出对企业提供“管家式服务”的培育平台建设解决方案。',
            content3:'汇集全国产业政策数据，深度解读政策申报条件，为企业智能匹配扶持政策，为政府快速识别目标企业，提高政策落地效率；通过地区政策全景分析、政策对比，为政策制定提供参考。',
            content2:'基于海量产业大数据，聚焦战略性新兴产业、区域重点产业集群，监测分析产业规模、产业链、产业创新、产业政策等维度，分析产业发展趋势，助力产业健康发展。',
            content1:'为识别、评估中小企业信用、创新、经营等风险，建设中小企业信用价值大数据平台，利用大数据技术依法采集、处理、挖掘、呈现中小企业工商、税务、司法、知识产权、投融资、能源、疫情等涉企数据，并通过大数据技术对多维涉企数据进行结构化收集与加工，建立企业价值评估报告模型，刻画企业价值轨迹、描述企业信用、创新、风险等状况，评价中小企业价值程度。同时，对多元化应用场景，推出一系列定制化解决方案，助力用户提高投融资决策、风险控制、企业洞察等方面能力。',
            content:'汇集专利、商标、版权、地理标志、集成电路布图等知识产权关键数据，提供知识产权信息查询、大数据统计分析、数据动态监控预警等智慧信息服务，为科技创新以及知识产权创造、运用、保护、管理、服务全链条提供全面的知识产权情报支撑。',
        }
    },
    methods: {
        btnClick() {
            this.$refs.dialog.show = true;
        }
    }
}
</script>
<style lang="scss" scoped>
.btn {
  width: 200px;
  height: 50px;
  background-color: #1935DE;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
}
.btns2 {
  display: flex;
  flex-direction: column;
  .btn {
    margin-right: 20px;
    cursor: pointer;
    border: 1px solid #1935DE;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 50px;
    font-size: 18px;
    color: #1935DE;
  }
  .info {
    background-color: #1935DE;
    color: #fff;
  }
}
</style>