<template>
  <div class="container" :style="bodyStyle">
    <div class="container-content" :class="{imgR:imgRight}">
        <div class="img-box" :style="imgStyle">
            <el-image style="width: 100%; height: 100%" :src="imageSrc" :fit="imgFit"></el-image>
        </div>
        <div class="message">
            <div class="message-title">{{ title }}</div>
            <div class="message-content">{{ content }}</div>
            <slot name="btns" v-if="showBtn">
                <div class="btns">
                    <div class="btn info" @click="$emit('clickBtn1')" >免费试用</div>
                    <div class="btn" @click="$emit('clickBtn2')">查看详情</div>
                </div>
            </slot>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        //外部样式
        bodyStyle:{
            type:Object,
            default(){
                return{}
            }
        },
        //图片样式
        imgStyle:{
            type:Object,
            default(){
                return{}
            }
        },
        //图片地址
        imageSrc:{
            type:String,
            default:""
        },
        //图片适应容器框
        imgFit:{
            type:String,
            default:"contain"
        },
        //图片是否显示在右边
        imgRight:{
            type:Boolean,
            default:false,
        },
        //标题
        title:{
            type:String,
            default:""
        },
        //文本内容
        content:{
            type:String,
            default:""
        },
        //是否显示按钮
        showBtn:{
            type:Boolean,
            default:true,
        }
    }
}
</script>

<style lang="scss" scoped>
.container{
    padding: 50px 0;
    background-color: #fff;
    &-content{
        width: 1200px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .message{
        width: 610px;
        box-sizing: border-box;
        &-title{
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 24px;
        }
        &-content{
            font-size: 16px;
            line-height: 1.8;
            text-align: justify;
            margin-bottom: 30px;
        }
    }
    .btns{
        display: flex;
        align-items: center;
        .btn{
            margin-right: 20px;
            cursor: pointer;
            border: 1px solid #1935DE;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 40px;
            font-size: 14px;
            color: #1935DE;
        }
        .info{
            background-color: #1935DE;
            color: #fff;
        }
    }
    .imgR{
        flex-direction: row-reverse;
    }
}
</style>